//---------------------------------------------
//
//  Main Nav Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../variables";

//---------------------------------------------
// 2. Component
//---------------------------------------------

.nav-main {
    font-family: 'Rubik', sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: -moz-linear-gradient(top,  rgba(10,26,36,1) 0%, rgba(125,185,232,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(10,26,36,1) 0%,rgba(125,185,232,0) 100%);
    background: linear-gradient(to bottom,  rgba(10,26,36,1) 0%,rgba(125,185,232,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1a24', endColorstr='#007db9e8',GradientType=0 );
    padding: 20px 0;

    @include media-breakpoint-down(lg) {
        position: static;
    }

    ul.navbar-nav {
        padding-left: 50px;

        @include media-breakpoint-down(lg) {
            padding-left: 0;
            text-align: center;
            margin-bottom: 40px;
        }

        li {
            margin-right: 25px;

            a {
                color: $white;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
                display: block;
                position: relative;
                transition: 200ms ease-in-out;

                &::before {
                    content: '';
                    position: absolute;
                    height: 2px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: none;
                    margin: auto;

                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                &:hover {
                    &::before {
                        background: $danger;
                    }
                }
            }
        }
    }

    .navbar-info {
        @include media-breakpoint-down(lg) {
            justify-content: center;
        }

        &__number {
            text-align: right;
            padding-right: 20px;
            font-size: 12px;
            color: $white;
            font-weight: 700;
            border-right: 1px solid rgba(255, 255, 255, 0.2);

            span {
                display: block;
                font-weight: 400;
            }
        }

        &__contact {
            text-align: left;
            padding-left: 20px;
            font-size: 12px;
            color: $white;
            font-weight: 700;
            border-left: 1px solid rgba(255, 255, 255, 0.2);

            span:not(.mail-element) {
                display: block;
            }

            a.mail-element,
            span.mail-element {
                color: $white;
                text-decoration: none;
                font-weight: 400;
                text-transform: uppercase;
            }

            b {
                color: $danger;
                margin: 0 10px;
            }
        }
    }
}

.navbar-toggler {
    color: white;
}