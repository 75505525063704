@mixin pageTitle {
  padding: 0 25%;
  margin-bottom: 50px;

  @include media-breakpoint-down(lg) {
    padding: 0 30px;
  }

  h2 {
    font-size: 42px;
    line-height: 48px;
    font-weight: 500;
    text-align: center;

    span {
      color: $purple;
    }
  }
}

@mixin pageDescription {
  text-align: center;
  padding: 0 20%;
  margin-bottom: 60px;

  @include media-breakpoint-down(lg) {
    padding: 0 30px;
  }

  h3, h4, h5 {
    margin: 50px 0 30px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #B4B8BB;
    font-weight: 300;

    span {
      color: $white;
      font-weight: 500;
    }
  }
}