.layout {
  display: flex;
  align-items: stretch;
  height: 100vh;
  width: 100%;

  &__sidebar {
    background-color: $darker;
  }

  &__content {
    flex: 1;
    padding: $general-padding;
    position: relative;
    overflow: scroll;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 300px;
      right: 0;
      opacity: 0.2;

      background-image: url("../images/domains-bg.jpg"), url("../images/dark-bg.png");
      background-size: cover;
      background-repeat: no-repeat, repeat;
      background-position: center bottom;
      background-blend-mode: luminosity;
    }

    &__head {
      padding: $general-padding;
      text-align: center;
      position: relative;
      z-index: 1;

      a {
        text-decoration: none;
        color: $danger;
        font-weight: 700;
      }
    }

    &__body {
      position: relative;
      z-index: 1;

      .text-section {
        padding: $general-padding;

        p {
          color: $secondary;
        }
      }
    }

    .brands-table {
      position: relative;
      z-index: 1;
      background: $darker;
      border-radius: 10px;

      &__body__item {
        border-radius: 0;

        .price-item {
          color: $danger;
          font-weight: 700;
        }

        &:nth-child(odd) {
          background: rgba(42, 62, 76, 0.2);
        }
      }
    }
  }
}

.layout__sidebar {
  max-width: 300px;
  display: flex;
  flex-direction: column;

  .sidebar-head {
    padding: $general-padding;
    border-bottom: 2px solid $dark;
    h5 {
      font-size: 12px;
      margin-bottom: 0;
      color: $secondary;
    }
  }

  .sidebar-content {
    flex: 1;

    .main-menu {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li a {
          text-decoration: none;
          color: $white;
          padding: 10px 30px;
          display: block;

          &:hover {
            background-color: $dark;
          }
        }
      }
    }
  }

  .sidebar-copyrights {
    padding: $general-padding;

    span {
      font-size: 14px;
      color: $secondary;
    }
  }
}