//---------------------------------------------
//
//  Icon Card Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../variables";

//---------------------------------------------
// 2. Component
//---------------------------------------------

.icon-card {
    display: flex;
    padding: 0 15px;

    @include media-breakpoint-only(md) {
        flex-direction: column;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }

    &__icon {
        width: 95px;
        height: 95px;
        border: 1px solid $light;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @include media-breakpoint-only(md) {
            margin-bottom: 20px;
        }

        i {
            font-size: 35px;
            color: $light;
        }
    }

    &__text {
        flex: 1;

        h2, h3, h4, h5 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        span {
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            
            b {
                font-weight: 700;
            }
        }
    }
}