//---------------------------------------------
//
//  Top Banner Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../variables";

//---------------------------------------------
// 2. Component
//---------------------------------------------

.top-banner {
    padding-top: 87px;
    overflow: hidden;

    &:before {
        content: " ";
        position: absolute;
        background-image: url(../images/top-banner-bg.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        filter: blur(5px);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &__inlay {
        min-height: 90vh;
        display: flex;
        flex-direction: column;
    }

    &__cta {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        h1, h2, h3, h4, h5 {
            margin-bottom: 20px;
            font-size: 42px;
            line-height: 48px;
        }

        button {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            background: $white;
            border-color: $white;
        }
    }
}