//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #1D2D39; // The default text color

$primary:    #0d6efd;
$secondary:  #6c757d;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #E74C3C;
$light:      #C4C4C4;
$dark-blue:  #273C74;
$dark:       #1D2D39;
$darker:     #19252E;
$darkest:    #1A2833;
$white:      #ffffff;
$black:      #000000;
$purple:     #4B64A8;

$general-padding: 30px;

// Media queries breakpoints
// -------------------------
// Breakpoints are defined as a map of (name: minimum width), order from small to large
$grid-breakpoints: (
  //xs: 0,					// PHONE PORTRAIT		0	- 543
  //sm: em(544px, 16px),	// PHONE LANDSCAPE		544	- 767
  //md: em(768px, 16px),	// TABLET PORTRAIT		768	- 991
  //lg: em(992px, 16px),	// TABLET LANDSCAPE		992	- 1199
  //xl: em(1200px, 16px),	// DESKTOP				1200 ~
  xs: 0, // PHONE PORTRAIT		0	- 543
  sm: 544px, // PHONE LANDSCAPE		544	- 767
  md: 768px, // TABLET PORTRAIT		768	- 991
  lg: 992px, // TABLET LANDSCAPE		992	- 1199
  xl: 1200px // DESKTOP				1200 ~
);