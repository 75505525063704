.image-card {
  margin: 0 20px;
  background: $darkest;
  padding: 40px;

  .image-card__image-outlay {
    background: radial-gradient(125.99% 125.99% at 19.21% -4.52%, #192533 0%, rgba(211, 232, 255, 0.640625) 35.94%, rgba(25, 37, 51, 0) 100%);
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.15));
    padding: 5px;
    width: 177px;
    height: 177px;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto auto 30px;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  &__image {
    overflow: hidden;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  &__role {
    h3, h4, h5 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  &__name {
    h3, h4, h5 {
      color: #BAC8E9;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }

  &__description {
    min-height: 105px;
    margin-bottom: 20px;

    span {
      font-size: 14px;
      font-weight: 300;
      opacity: 0.5;
      color: $white;
      display: block;
    }
  }

  &__more {
    a {
      color: $white;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        color: $danger;
      }
    }
  }

  &.image-card--horizontal {
    display: flex;
    align-items: center;

    .image-card__image-outlay {
      margin: 0 40px 0 0;
    }

    .image-card__text {
      flex: 1;

      .image-card__description {
        span {
          margin-bottom: 25px;

          &:first-child {
            opacity: 1;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.image-card--image-right {
      .image-card__image-outlay {
        margin: 0 0 0 40px;
        order: 1;
      }
    }
  }
}