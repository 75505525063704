//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "mixins/general-mixins";
@import "~slick-carousel/slick/slick";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    background: $body-color;
    color: $white;
    font-family: 'Roboto Slab', sans-serif;
}

.brands-listing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    font-family: 'Rubik', sans-serif;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        justify-content: center;
    }

    &__item {
        @include media-breakpoint-up(lg) {
            flex: 1;
        }

        @include media-breakpoint-down(lg) {
            margin: 0 30px 30px;
        }

        a {
            font-size: 14px;
            font-weight: 500;
            color: $white;
            text-decoration: none;
        }
    }

    &__more {

        @include media-breakpoint-down(lg) {
            margin: 0 30px 30px;
        }

        a {
            font-size: 14px;
            font-weight: 500;
            color: $white;
            text-decoration: none;

            span {
                font-size: 22px;
                font-weight: 700;
            }
        }
    }
}

.clients-section,
.technical-partners {
    background: $darker;
}

.about-us {
    padding: 80px 0;
    overflow: hidden;

    &__title {
        @include pageTitle;
    }

    &__text {
        @include pageDescription;
    }
}

.stats-section {
    padding: 40px 0 80px;
    background-image: url(../images/globe.png);
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;

    &__graph {
        background: linear-gradient(327.23deg, rgba(7, 13, 27, 0.5) -3.56%, rgba(52, 74, 100, 0.381404) 75.02%, rgba(29, 64, 129, 0.0257094) 142.73%, rgba(28, 62, 129, 0.0078125) 142.74%, rgba(55, 71, 102, 0) 142.75%);
        backdrop-filter: blur(8px);
        padding: 40px;
        border-radius: 6px;

        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }

        &__text {
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                margin-bottom: 50px;
            }

            h2, h3, h4, h5 {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            span {
                display: block;
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 300;
                color: $white;
                opacity: 0.7;
            }

            button {
                border-color: $white;
                color: $white;
                text-transform: uppercase;

                &:hover {
                    background-color: $white;
                }
            }
        }

        &__image img {
            width: 100%;
        }
    }

    .stats-info {
        @include media-breakpoint-up(md) {
            padding-left: 70px;
        }

        &__text {
            margin-bottom: 30px;

            h3, h4, h5 {
                font-size: 22px;
                font-weight: 300;
                line-height: 34px;
    
                span {
                    display: block;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 38px;
                }
            }
        }

        &__download {
            display: inline-flex;
            align-items: center;
            padding: 30px 70px;
            border-radius: 8px;
            border: none;
            color: $white;
            background: linear-gradient(180deg, #1B2835 0%, rgba(27, 40, 53, 0) 100%);
            margin-bottom: 40px;
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                padding: 30px;
            }

            &__text {
                flex: 1;
                text-align: left;

                span {
                    font-size: 28px;
                    line-height: 36px;
                    font-weight: 500;
                    color: $white;
                }
            }

            &__icon {
                margin-left: 70px;
            }
        }

        .prices-section {
            display: flex;
            justify-content: space-between;
            position: relative;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            &__item {
                flex: 1;
                max-width: 180px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 40px;
                    max-width: none;
                }

                &__year {
                    text-align: center;
                    padding-bottom: 35px;
                    margin-bottom: 35px;
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        padding-bottom: 0;
                        margin-bottom: 20px;
                        text-align: left;
                    }

                    span {
                        font-size: 18px;
                        font-weight: 400;
                        font-family: 'Rubik', sans-serif;

                        b {
                            font-weight: 400;
                            font-size: 14px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -10px;
                        margin: auto;
                        width: 20px;
                        height: 20px;
                        background: $danger;
                        border-radius: 50%;
                        transition: 200ms ease-in-out;

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                }

                &__profit {
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        text-align: left;
                    }

                    h3, h4, h5 {
                        font-size: 26px;
                        font-weight: 300;

                        @include media-breakpoint-down(lg) {
                            font-size: 20px;
                        }

                        span {
                            font-weight: 700;
                        }
                    }
                }

                &__revenue {
                    padding-left: 15px;

                    span {
                        font-family: 'Rubik', sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        color: #8D9BBC;

                        b {
                            color: $white;
                            font-weight: 600;
                        }
                    }

                    ul li {
                        font-family: 'Rubik', sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        color: #8D9BBC;
                    }
                }

                &:hover {
                    .prices-section__item__year {
                        &::before {
                            transform: scale(1.3);
                        }
                    }
                }
            }

            &::before {
                content: '';
                position: absolute;
                height: 1px;
                left: 90px;
                right: 90px;
                top: 62px;
                margin: auto;
                background: #7383AA;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }
}

.team {
    padding: 80px 0;
    overflow: hidden;

    &__inlay {
        overflow: hidden;
    }

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        h2, h3, h4, h5 {
            margin-right: 20px;
            font-size: 42px;
            font-weight: 500;

            @include media-breakpoint-down(sm) {
                font-size: 25px;
            }
        }

        button,
        a {
            font-size: 14px;
            font-weight: 700;
        }
    }

    &__slider {
        position: relative;
        margin: 0 -20px;
    }
}

.title-section {
    padding: 120px 0 80px;
}

.page-title-center {
    @include pageTitle;
}

.page-description-center {
    @include pageDescription;
}

.our-team-section {
    margin-bottom: 80px;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/main-nav";
@import "components/top-banner";
@import "components/icon-card";
@import "components/image-card";
@import "components/brands-table";
@import "components/footer";
@import "pages/domains-page";

.btn {
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;

    &.btn-light {
        background: $white;
        border-color: $white;
    }

    &.btn-outline-light {
        border-color: $white;
        color: $white;

        &:hover {
            background: $white;
            color: $black;
        }
    }
}

.slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    position: absolute;
    top: -80px;
    right: 30px;

    @include media-breakpoint-down(md) {
        top: auto;
        bottom: -40px;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        justify-content: center;
    }

    li {
        position: relative;
        width: 10px;
        height: 10px;
        margin: 0 5px;

        &:hover {
            cursor: pointer;
        }

        &::before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $white;
            opacity: 0.3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &.slick-active {
            &::before {
                background: $danger;
                opacity: 1;
            }
        }
    }

    button {
        display: none;
    }
}

.text-section {
    h3, h4, h5 {
        margin: 50px 0 30px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        color: #B4B8BB;
        font-weight: 300;

        span {
            color: $white;
            font-weight: 500;
        }
    }
}