.brands-table {
  &__head {
    display: flex;
    background: $darker;
    padding: 15px 10px;
    border-radius: 10px;

    &__col {
      flex: 1;
      text-align: center;

      h1, h2, h3, h4, h5 {
        margin-bottom: 0;
      }
    }
  }

  &__body__item {
    display: flex;
    padding: 15px 10px;
    border-radius: 10px;
    align-items: center;

    &:nth-child(even) {
      background: rgba(25, 37, 46, 0.3);
    }

    .brands-table__body__col {
      flex: 1;
      text-align: center;
    }
  }
}